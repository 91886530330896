import React, { useState } from 'react';
import './Contact.scss';
import { useTranslation } from 'react-i18next';

export default function Contact() {
    const { t } = useTranslation();  // Use the useTranslation hook to access translation function
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const mailto = `mailto:alexander.koch@uxnetwork.eu?subject=${encodeURIComponent(t('contact.subject'))}&body=${encodeURIComponent(message)}`;
        window.open(mailto, '_blank');
    };

    return (
        <section id="contact" className="py-5">
            <div className="container">
                <h2 className="text-center">{t('contact.title')}</h2>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <textarea
                                    className="form-control"
                                    id="message"
                                    rows="6"
                                    placeholder={t('contact.placeholder')}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                ></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">{t('contact.sendButton')}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
