import React from 'react'
import './Footer.scss';

export default function Footer() {
    return (
        <footer>
            <p className={"opacity-50"}>
               Made by Alexander Koch
            </p>
        </footer>
    )
}
